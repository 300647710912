import NetworkUtil from "./NetworkUtil";
import Facility from "../types/Facility";
import PodSched, {PodSchedForSelect} from "../types/PodSched";
import { Patient } from "../types/Patient";
import { PasswordConfigurationValues } from "../types/PasswordConfig";

export default class AdminAPI {
    static getAllSamplesForAdminHome() {
      throw new Error("Method not implemented.");
    }

    public static async getPermissions():Promise<{isGlobalAdminorLab:boolean, isGlobal:boolean, isAdmin:boolean}>{
        return NetworkUtil.makeGet("/api/admin/permissions");
    }
    
    public static async getLabValidationTestTypes():Promise<{success:boolean, reason:string, data?}>{
        return NetworkUtil.makeGet("/api/admin/getLabValidationTestTypes");
    }

    public static async getAllResultTypes():Promise<{label:string, value:number, description:string}[]>{
        return NetworkUtil.makeGet("/api/admin/resulttypes");
    }
    public static async getAllEnabledResultTypes():Promise<{label:string, value:number, description:string}[]>{
        return NetworkUtil.makeGet("/api/admin/enabledresulttypes");
    }
    public static async getFacilitiesForSubmissionForm(active:boolean=false):Promise<{authorizedFacilities:Facility[]}>{
        return NetworkUtil.makePost("/api/admin/getFacilitiesForSubmissionForm", {active:active})
    }

    public static async getFacilityServiceCommTemplateStatus():Promise<{data?}>{
        return NetworkUtil.makeGet("/api/admin/getFacilityServiceCommTemplateStatus")
    }

    public static async getFacilityServicePricing():Promise<{data?}>{
        return NetworkUtil.makeGet("/api/admin/getFacilityServicePricing")
    }

    public static async getFacilitiesWithAddress(active:boolean=false):Promise<{label:string, value:number}[]>{
        return NetworkUtil.makePost("/api/admin/facilities", {active:active})
    }

    public static async getFacilitiesGroups(active:boolean=false):Promise<{label:string, value:string}>{
        return NetworkUtil.makePost("/api/admin/facilityGroups", {active:active})
    }

    public static async getBarcodeStyle():Promise<{isOneD:boolean, success:boolean, reason:string}>{
        return NetworkUtil.makeGet("/api/admin/settings/tableBarcodeStyle");
    }

    public static async getTablePlatingDirection():Promise<{isHorizontal:boolean, success:boolean, reason:string}>{
        return NetworkUtil.makeGet("/api/admin/settings/tablePlatingDirection");
    }

    public static async getAllPods(includeData:boolean=false, active:boolean=false):Promise<{success:boolean,reason:string, data:{label:string,value:(number | PodSchedForSelect)}[]}>{
        return NetworkUtil.makePost("/api/admin/pods/filter", {includedata: includeData, active: active});
    }

    public static async getSpecimenLabels(body):Promise<{success:boolean, idList:[]}>{
        return NetworkUtil.makePost("/api/admin/specimen", body)
    }

    public static async getAuthenticated(body):Promise<{success:boolean, reason:string, user:any, groups:string[]}>{
        return NetworkUtil.makePost("/api/admin/authenticated", body)
    }

    public static async createUserProfile(body):Promise<{success:boolean,reason:string,user:any}>{
        return NetworkUtil.makePost("/api/admin/createUser",body)
    }

    public static async setPswdConfig(body):Promise<{success:boolean,reason:string,data:PasswordConfigurationValues}>{
        return NetworkUtil.makePost("/api/admin/settings/setPswdConfig", body);
    }    
    
    public static async createFakeSamples(body):Promise<{success:boolean,reason:string,samples:any}>{
        return NetworkUtil.makePost("/api/admin/createFakeSamples",body)
    }

    public static async createImportedSamples(body):Promise<{success:boolean,reason:string,createdSamples:any}>{
        return NetworkUtil.makePost("/api/admin/createImportedSamples",body)
    }

    //route not made yet
    public static async createBanner(banner):Promise<{success:boolean,reason:string}>{
        return NetworkUtil.makePost("/api/admin/createBanner",banner)
    }

    public static async updateBanner(updatedBanner):Promise<{success:boolean,reason:string}>{
        return NetworkUtil.makePost("/api/admin/updateBanner",updatedBanner)
    }

    public static async filterBanner(filter:{filter:{ProductID,Product?,Active?}}):Promise<{success:boolean, reason:string, data}>{
        return NetworkUtil.makePost("/api/admin/filterBanner", filter)
    }

    public static async getProductIDofUser():Promise<{brandingID:number, isGlobal:boolean}>{
        return NetworkUtil.makeGet("/api/admin/getProductIDofUser");
    }

    public static async documentUpload(ID, url:string, documentName:string, file?:Blob):Promise<{success:boolean, reason:string, url?:string}>{
        let data = new FormData()
        data.set('id', ID)
        data.set('url', url)
        data.set('documentName', documentName)
        data.set('file', file)
        return NetworkUtil.postForm("/api/admin/documentUpload", data)
    }

    public static async getDocuments():Promise<{show:boolean, documents?}>{
        return NetworkUtil.makeGet("/api/admin/getDocuments")
    }

    public static async filterDocuments(filter:{filter:{ID}}):Promise<{success:boolean, reason:string, data?}>{
        return NetworkUtil.makePost("/api/admin/filterDocuments", filter)
    }

    public static async deleteDocument(id, url):Promise<{success:boolean, reason:string}>{
        return NetworkUtil.makePost("/api/admin/deleteDocument", {id: id, url: url})
    }

}