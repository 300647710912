import React from "react";
import Overlay from "../Overlay";
import Select from "react-select";
import moment from "moment";
import {getDateOrNull, getSelectLabels, getNameFromID, showModalNoOutsideClick} from "../../util/FormatUtil";
import TestType from "../../types/TestType";
import {sweetalert} from "../../App";
import DatePicker from 'react-datepicker'
import SystemAPI from "../../network/SystemAPI";
import {CustomFieldType, CustomFields} from "../../types/CustomFieldType";
import TestsAPI from "../../network/TestsAPI";
import Validator, {ValidationEntry} from "../../validation/Validator";
import {Validators} from "../../validation/Validators";
import AdminAPI from "../../network/AdminAPI";
import {AdminPages} from "./AdminNav";
import ServicesAPI from "../../network/ServicesAPI";
import { isEmptyObject } from "jquery";
import { ServiceFormModal } from "../modals/ServiceFormModal";
import { PatientInfoModal } from "../modals/PatientInfoModal";
import { InsuranceModal } from "../modals/InsuranceModal";
import WalkinAPI from "../../network/WalkinAPI";
import { ImportantInformationModal } from "../modals/ImportantInformationModal";
import Parser from "html-react-parser";
// import { ROIModal } from "../modals/ROIModal";
import InformedConsentModal from "../modals/InformedConsentModal";
import { CustomerAPI } from "../../network/CustomerAPI";
import { isBlank, isOnlyWhitespace } from "../../util/ValidationUtil";
import PatientAPI from "../../network/PatientAPI";
import CustomFieldsAPI from "../../network/CustomFieldsAPI";
import Price, { FacilityPrice } from "../../types/Price";
import PricingAPI from "../../network/PricingAPI";
import {PaymentFormModal} from "../modals/PaymentFormModal";
import  Dropzone  from 'react-dropzone';
import  SubmissionFileUploadModal from "../modals/SubmissionFileUploadModal";
import { devComprehensiveServiceIDs, devEssentialNonUpgradableServiceIDs, devEssentialUpgradableServiceIDs, productionComprehensiveServiceIDs, productionEssentialNonUpgradableServiceIDs, productionEssentialUpgradableServiceIDs } from "../../types/Service";

interface WalkinFormState{
    showLoading:boolean
    patientInformation
    selectedFacilityIDLabel?
    Memo?
    SpecimenID?
    facilities?
    facilityLabels
    tests:TestType[]
    Lot?
    selectedTests:{value, label}[] | any
    collectionDate?
    testOrderedDate?
    isSubmitting
    validSpecimenSources?
    customQuestions?
    customPatientFields?
    sources?
    services
    selectedServices    
    serviceLabels
    insuranceInformation
    showInsurance:boolean
    showFileUpload:boolean
    associatedTests:TestType[]
    imageSaved:boolean
    selectedServiceID?:number
    serviceSpecificCustomQuestions?
    savedServices?
    savedPatientInformation?
    savedInsuranceInformation?
    savedFileUpload?
    servicesObjectsArray?
    states?
    race?
    gender?
    ethnicity?
    countries?
    savedImportantInformation
    acknowledged
    consentEntities: {
        ID: number;
        Name: string;
        FacilityID: number;
        AccessCode: string;
    }[];
    declineConsent:boolean;
    // roiObjectsArray?;
    // savedROIServices?;
    legalName?
    portalPatients?
    prices?
    associatedPrices?
    savedPaymentInfo?
    paymentInfo:{formData,totalAmount,lineItems}
    showPaymentInfo?:boolean
    submissionFiles?
}

interface WalkinFormProps{}

export default class WalkinForm extends React.Component<WalkinFormProps, WalkinFormState>{
    // private roiRef: any;
    private patientInfoRef: any;
    private paymentFormRef: any;
    private submissionFileRef: any;

    constructor(props) {
        super(props);
        this.state = {
            services: [],
            serviceLabels: [],
            facilityLabels: [],
            selectedServices: [],
            selectedTests: [],
            patientInformation:{},
            collectionDate:new Date(),
            testOrderedDate:new Date(),
            validSpecimenSources:[],
            sources: [],
            showLoading:false,
            isSubmitting: false,
            tests:[],
            customQuestions: [],
            customPatientFields: [],
            insuranceInformation:{},
            showInsurance: false,
            showFileUpload: false,
            associatedTests: [],
            imageSaved: false,
            selectedFacilityIDLabel: null,
            savedServices: [],
            states:[],
            gender:[],
            ethnicity:[],
            race:[],
            countries:[],
            servicesObjectsArray: [],
            savedImportantInformation:false,
            acknowledged: false,
            consentEntities: [],
            declineConsent:false,
            // roiObjectsArray: [],
            // savedROIServices: [],
            portalPatients: [],
            prices: [],
            associatedPrices:[],
            showPaymentInfo: false,
            paymentInfo: {formData:null,totalAmount:0, lineItems:null},
            savedPaymentInfo: false,
            submissionFiles: [],
        };
        this.submit = this.submit.bind(this);
        this.assignClearInsurance = this.assignClearInsurance.bind(this);
        this.assignClearService = this.assignClearService.bind(this);
        // this.roiRef = React.createRef();
        this.patientInfoRef = React.createRef();
        this.paymentFormRef = React.createRef();
        this.submissionFileRef = React.createRef();

        this.handlePaymentInfo = this.handlePaymentInfo.bind(this)
        this.refreshSelectedFacility = this.refreshSelectedFacility.bind(this);
        
    }

    componentDidMount() {
        document.title = 'Submission Form';
        this.setState({showLoading: true}, () => {
            CustomerAPI.getPolicyName().then((data) => {
                this.setState({ legalName: data.name });
            });
            TestsAPI.getValidSpecimenSources().then(data => {
                this.setState({validSpecimenSources: data})
            })
            AdminAPI.getFacilitiesForSubmissionForm().then(data => {
                this.setState({ facilities: data.authorizedFacilities, facilityLabels: getSelectLabels(data.authorizedFacilities)})
            })
            PatientAPI.getPatientsFromPatientPortal().then(async data => {
                this.setState({portalPatients: data.patients})
            })
            TestsAPI.getAllTestDetails().then(data => {
                let enabledTests = data.tests.filter(t => t.IsEnabled)
                this.setState({tests: enabledTests, showLoading: false})
            });
            CustomFieldsAPI.getAllCustomFields().then(data =>{
                this.setState({
                    customQuestions: data.data.customQuestions,
                    customPatientFields: data.data.customPatientFields,
                  });
            })
            SystemAPI.getAllStates().then(data => {
                this.setState({states: data})
            })
            SystemAPI.getAllRaces().then(data => {
                this.setState({race: data})
            })
            SystemAPI.getAllGenders().then(data => {
                this.setState({gender: data})
            })
            SystemAPI.getAllEthnicities().then(data => {
                this.setState({ethnicity: data})
            })
            SystemAPI.getAllCountries().then(data => {
                this.setState({countries: data})
            })
            ServicesAPI.getServicesForWalkinForm().then(response => {
                this.setState({services: response.data, serviceLabels: getSelectLabels(response.data)});
            });
            SystemAPI.getConsentEntities().then((data) => {
                this.setState({ consentEntities: data });

            });
        })
    }


    refreshSelectedFacility(){
        // ANetCustomerProfile created for Facility via PaymentFormModal
        // reload facilities and selectedFacility passed into PaymentFormModal?
        AdminAPI.getFacilitiesForSubmissionForm().then(data => {
            this.setState({ 
                facilities: data.authorizedFacilities, 
                facilityLabels: getSelectLabels(data.authorizedFacilities)
            })
        })
    }

    async submit(){
        this.setState({showLoading: true, isSubmitting: true})
        if (!this.state.savedPaymentInfo &&
            this.state.selectedServices.some(s=>s.EnablePayments)) {
            this.setState({showLoading: false, isSubmitting: false})
            return sweetalert.fire({
                icon: "error",
                title: "",
                text: "Please enter Payment Information.",
            });
        }

        if (!this.state.savedImportantInformation) {
            this.setState({showLoading: false, isSubmitting: false})
            return sweetalert.fire({
                icon: "error",
                title: "",
                text: "Important Information must be reviewed.",
            });
        }

        let insuranceInfo = {}
        let insuranceCards = []

        let formValidation = {
            FacilityID: this.state.selectedFacilityIDLabel,
            TestOrderedDate: this.state.testOrderedDate,
            CollectionDate: this.state.collectionDate,
        }

        let validator = new Validator<any>()
            .withSimpleValidation("FacilityID", Validators.requireNonNullValidator("Facility"))
            .withComposedValidation("CollectionDate", new ValidationEntry(Validators.requireNotFutureDate("Collection Date")), new ValidationEntry(Validators.requireNonNullValidator("Collection Date")))
            .withComposedValidation("TestOrderedDate", new ValidationEntry(Validators.requireNotFutureDate("Test Ordered Date")), new ValidationEntry(Validators.requireNonNullValidator("Test Ordered Date")))
        let validationResponse = validator.validate(formValidation);
        if(!validationResponse.success) {
            this.setState({showLoading: false, isSubmitting: false})
            return sweetalert.fire({icon: 'error', title: '', text: validationResponse.error});
        }

         //check Patient Information
        if(!this.state.patientInformation || JSON.stringify(this.state.patientInformation) === '{}'){
            this.setState({showLoading: false, isSubmitting: false})
            return sweetalert.fire({icon: 'error', title: '', text: `Patient Information must be complete`})
        }

        let patientInformationCopy = JSON.parse(JSON.stringify(this.state.patientInformation)); 

        //get patient email based on active email custom patient field(first one if there are multiple) instead of hard coded 'Email'
        for (let i = 0; i < this.state.customPatientFields.length; i++) {
            if(this.state.customPatientFields[i].FieldTypeID === CustomFields.EMAIL_FIELD && this.state.customPatientFields[i].Options.isActive){
                patientInformationCopy.PatientEmail = this.state.patientInformation && this.state.patientInformation.CustomFieldData && this.state.patientInformation.CustomFieldData[this.state.customPatientFields[i].DisplayName] ? this.state.patientInformation.CustomFieldData[this.state.customPatientFields[i].DisplayName] : null;
                break;
            }            
        }
        //get patient phone based on active phone custom patient field(first one if there are multiple) instead of hard coded 'Phone'
        for (let i = 0; i < this.state.customPatientFields.length; i++) {
            if(this.state.customPatientFields[i].FieldTypeID === CustomFields.PHONE_FIELD && this.state.customPatientFields[i].Options.isActive){
                patientInformationCopy.PatientPhone = this.state.patientInformation && this.state.patientInformation.CustomFieldData && this.state.patientInformation.CustomFieldData[this.state.customPatientFields[i].DisplayName] ? this.state.patientInformation.CustomFieldData[this.state.customPatientFields[i].DisplayName] : null;
                break;
            }            
        }

        patientInformationCopy.UID = this.state.patientInformation && this.state.patientInformation.CustomFieldData && this.state.patientInformation.CustomFieldData.uid ? this.state.patientInformation.CustomFieldData.uid : null;
        patientInformationCopy.CreatedDate = this.state.patientInformation && this.state.patientInformation.CustomFieldData && this.state.patientInformation.CustomFieldData.createdDate ? this.state.patientInformation.CustomFieldData.createdDate : null;
        patientInformationCopy.PatientFirstName = this.state.patientInformation && this.state.patientInformation.CustomFieldData && this.state.patientInformation.CustomFieldData['First Name'] ? this.state.patientInformation.CustomFieldData['First Name'] : null;
        patientInformationCopy.PatientMiddleName = this.state.patientInformation && this.state.patientInformation.CustomFieldData && this.state.patientInformation.CustomFieldData['Middle Name'] ? this.state.patientInformation.CustomFieldData['Middle Name'] : null;
        patientInformationCopy.PatientLastName = this.state.patientInformation && this.state.patientInformation.CustomFieldData && this.state.patientInformation.CustomFieldData['Last Name'] ? this.state.patientInformation.CustomFieldData['Last Name'] : null;
        patientInformationCopy.PatientDOB = this.state.patientInformation && this.state.patientInformation.CustomFieldData && this.state.patientInformation.CustomFieldData['Date of Birth'] ? this.state.patientInformation.CustomFieldData['Date of Birth'] : null;
        patientInformationCopy.PatientAddress = this.state.patientInformation && this.state.patientInformation.CustomFieldData && this.state.patientInformation.CustomFieldData.Address ? this.state.patientInformation.CustomFieldData.Address : null;
        patientInformationCopy.PatientAddress2 = this.state.patientInformation && this.state.patientInformation.CustomFieldData && this.state.patientInformation.CustomFieldData['Address Cont.'] ? this.state.patientInformation.CustomFieldData['Address Cont.'] : null;
        patientInformationCopy.PatientCity = this.state.patientInformation && this.state.patientInformation.CustomFieldData && this.state.patientInformation.CustomFieldData.City ? this.state.patientInformation.CustomFieldData.City : null;
        patientInformationCopy.PatientState = this.state.patientInformation && this.state.patientInformation.CustomFieldData && this.state.patientInformation.CustomFieldData.State ? this.state.states.find(f => f.label === this.state.patientInformation.CustomFieldData.State).value : null;
        patientInformationCopy.PatientCounty = this.state.patientInformation && this.state.patientInformation.CustomFieldData && this.state.patientInformation.CustomFieldData.County ? this.state.patientInformation.CustomFieldData.County : null;
        patientInformationCopy.PatientZip = this.state.patientInformation && this.state.patientInformation.CustomFieldData && this.state.patientInformation.CustomFieldData.Zipcode ? this.state.patientInformation.CustomFieldData.Zipcode : null;
        patientInformationCopy.PatientCountry = this.state.patientInformation && this.state.patientInformation.CustomFieldData && this.state.patientInformation.CustomFieldData.Country ? this.state.countries.find(f => f.label === this.state.patientInformation.CustomFieldData.Country).value : null;
        patientInformationCopy.PatientGenderID = this.state.patientInformation && this.state.patientInformation.CustomFieldData && this.state.patientInformation.CustomFieldData.Gender ? this.state.gender.find(f => f.label === this.state.patientInformation.CustomFieldData.Gender).value : null;
        patientInformationCopy.PatientEthnicityID = this.state.patientInformation && this.state.patientInformation.CustomFieldData && this.state.patientInformation.CustomFieldData.Ethnicity ? this.state.ethnicity.find(f => f.label === this.state.patientInformation.CustomFieldData.Ethnicity).value : null;
        patientInformationCopy.PatientRaceID = this.state.patientInformation && this.state.patientInformation.CustomFieldData && this.state.patientInformation.CustomFieldData.Race ? this.state.race.find(f => f.label === this.state.patientInformation.CustomFieldData.Race).value : null;
        patientInformationCopy.GuardianFirstName = this.state.patientInformation && this.state.patientInformation.CustomFieldData && this.state.patientInformation.CustomFieldData['Guardian First Name'] ? this.state.patientInformation.CustomFieldData['Guardian First Name'] : null;
        patientInformationCopy.GuardianLastName = this.state.patientInformation && this.state.patientInformation.CustomFieldData && this.state.patientInformation.CustomFieldData['Guardian Last Name'] ? this.state.patientInformation.CustomFieldData['Guardian Last Name'] : null;
        patientInformationCopy.FacilityID = this.state.selectedFacilityIDLabel ? this.state.selectedFacilityIDLabel.value : null;
        patientInformationCopy.CollectionDate = getDateOrNull(this.state.collectionDate);
        patientInformationCopy.TestOrderedDate = getDateOrNull(this.state.testOrderedDate);  

        delete patientInformationCopy.CustomFieldData.Address;
        delete patientInformationCopy.CustomFieldData.City;
        delete patientInformationCopy.CustomFieldData.Country;
        delete patientInformationCopy.CustomFieldData.County;
        delete patientInformationCopy.CustomFieldData['Date of Birth'];
        delete patientInformationCopy.CustomFieldData.Email;
        delete patientInformationCopy.CustomFieldData.Ethnicity;
        delete patientInformationCopy.CustomFieldData['First Name'];
        delete patientInformationCopy.CustomFieldData.Gender;
        delete patientInformationCopy.CustomFieldData['Last Name'];
        delete patientInformationCopy.CustomFieldData['Middle Name'];
        delete patientInformationCopy.CustomFieldData.Phone;
        delete patientInformationCopy.CustomFieldData.Race;
        delete patientInformationCopy.CustomFieldData.State;
        delete patientInformationCopy.CustomFieldData.Zipcode;
        delete patientInformationCopy.CustomFieldData.uid;
        delete patientInformationCopy.CustomFieldData['Guardian First Name'];
        delete patientInformationCopy.CustomFieldData['Guardian Last Name'];
        
        //set insuranceCards if provided
        if(!isEmptyObject(this.state.insuranceInformation)){
            insuranceInfo = JSON.parse(JSON.stringify(this.state.insuranceInformation))
            insuranceCards = [this.state.insuranceInformation.FrontCardImgFile, this.state.insuranceInformation.BackCardImgFile]
            delete insuranceInfo['FrontCardImgFile']
            delete insuranceInfo['BackCardImgFile']
            delete insuranceInfo['errors']
        }

        //check Service Information
        if(!this.state.servicesObjectsArray || this.state.servicesObjectsArray.length < 1){
            this.setState({showLoading: false, isSubmitting: false})
            return sweetalert.fire({icon: 'error', title: '', text: `Sample Information must be complete`})
        }

        if(this.state.servicesObjectsArray.length !== this.state.selectedServices.length){
            this.setState({showLoading: false, isSubmitting: false})
            return sweetalert.fire({icon: 'error', title: '', text: `All Sample Information must be complete`})
        }

        if (!this.state.acknowledged) {
            this.setState({showLoading: false, isSubmitting: false})
            return sweetalert.fire({
              icon: "error",
              title: "",
              text: "Summary acknowledgment box must be checked.",
            });
        }

        if(this.state.servicesObjectsArray && this.state.servicesObjectsArray?.length > 0){
            for (let i = 0; i < this.state.servicesObjectsArray.length; i++) {
                const element = this.state.servicesObjectsArray[i];
                if(!element.sampleInfo.specimenID || element.sampleInfo.specimenID.length < 8){
                    return sweetalert.fire({icon: 'error', title: '', text: `Specimen ID must be at least 8 characters for ${getNameFromID(element.serviceID, this.state.services)}.`})
                }
                if(element.sampleInfo.selectedTests.length < 1){
                    this.setState({showLoading: false, isSubmitting: false})
                    return sweetalert.fire({icon: 'error', title: '', text: `Sample Information must be completed for ${getNameFromID(element.serviceID, this.state.services)}`})
                }
                if(element.sampleInfo.isLabService){
                    if(isBlank(element.sampleInfo.specimenID) || isOnlyWhitespace(element.sampleInfo.specimenID)){
                        this.setState({showLoading: false, isSubmitting: false})
                        return sweetalert.fire({icon: "error", title: "", text: `Specimen ID is blank for ${getNameFromID(element.serviceID, this.state.services)}`});
                    }
                }
                if(!element.sampleInfo.specimenSourceID){
                    this.setState({showLoading: false, isSubmitting: false})
                    return sweetalert.fire({icon: 'error', title: '', text: `Specimen Source must be selected`})
                }

                
                if(!patientInformationCopy.PatientEmail || patientInformationCopy.PatientEmail.length < 1 || !patientInformationCopy.PatientPhone || patientInformationCopy.PatientPhone.length < 1){
                    if(typeof patientInformationCopy.PatientEmail === 'undefined' && typeof patientInformationCopy.PatientPhone === 'undefined'){
                        console.log('no patient email or phone fields active')
                    }
                    else{
                        let result = await WalkinAPI.getEnabledComms(patientInformationCopy.FacilityID, element.serviceID)
                        if(!result.success){
                            this.setState({showLoading: false, isSubmitting: false})
                            return sweetalert.fire({icon: 'error', title: '', text: result.reason})
                        }

                        if(typeof patientInformationCopy.PatientEmail != 'undefined' && typeof patientInformationCopy.PatientPhone != 'undefined'){ 
                            console.log('both patient email and phone fields active')
                            if(result.emailIsEnabled && result.smsIsEnabled && (!patientInformationCopy.PatientEmail || patientInformationCopy.PatientEmail.length < 1) && (!patientInformationCopy.PatientPhone || patientInformationCopy.PatientPhone.length < 1)){
                                let swalResult = await sweetalert
                                    .fire({
                                    icon: 'warning',
                                    title: "",
                                    html: `<h4>Can not send text or email communications for:</h4> <h5 style="margin-top: 20px;font-weight:bold;">${this.state.services.find(f => f.ID === element.serviceID).Name}</h5><p style="margin-top: 20px;">Please check Facility Management and Patient Field settings</p>`,
                                    showCloseButton: false,
                                    showDenyButton: true,
                                    showCancelButton: false,
                                    confirmButtonText: "Continue",
                                    denyButtonText: `Go Back`,
                                    denyButtonColor: 'green',
                                    })
                                if (swalResult.isDenied) {
                                    this.setState({ showLoading: false, isSubmitting: false })
                                    return;  
                                }
                            }
                            else if(result.emailIsEnabled && (!patientInformationCopy.PatientEmail || patientInformationCopy.PatientEmail.length < 1)){
                                let swalResult = await sweetalert
                                    .fire({
                                    icon: 'warning',
                                    title: "",
                                    html: `<h4>Can not send email communications for:</h4> <h5 style="margin-top: 20px;font-weight:bold;">${this.state.services.find(f => f.ID === element.serviceID).Name}</h5><p style="margin-top: 20px;">Please check Facility Management and Patient Field settings</p>`,
                                    showCloseButton: false,
                                    showDenyButton: true,
                                    showCancelButton: false,
                                    confirmButtonText: "Continue",
                                    denyButtonText: `Go Back`,
                                    denyButtonColor: 'green',
                                    })
                                if (swalResult.isDenied) {
                                    this.setState({ showLoading: false, isSubmitting: false })
                                    return;  
                                }
                            }
                            else if(result.smsIsEnabled && (!patientInformationCopy.PatientPhone || patientInformationCopy.PatientPhone.length < 1)){
                                let swalResult = await sweetalert
                                    .fire({
                                    icon: 'warning',
                                    title: "",
                                    html: `<h4>Can not send text communications for:</h4> <h5 style="margin-top: 20px;font-weight:bold;">${this.state.services.find(f => f.ID === element.serviceID).Name}</h5><p style="margin-top: 20px;">Please check Facility Management and Patient Field settings</p>`,
                                    showCloseButton: false,
                                    showDenyButton: true,
                                    showCancelButton: false,
                                    confirmButtonText: "Continue",
                                    denyButtonText: `Go Back`,
                                    denyButtonColor: 'green',
                                    })
                                if (swalResult.isDenied) {
                                    this.setState({ showLoading: false, isSubmitting: false })
                                    return;  
                                }
                            }
                            else{
                                console.log('email and text comms are not enabled or data requirement was met')
                            }
                        }
                        else if(typeof patientInformationCopy.PatientEmail != 'undefined' && typeof patientInformationCopy.PatientPhone === 'undefined' && result.emailIsEnabled && (patientInformationCopy.PatientEmail === null || patientInformationCopy.PatientEmail.length < 1)){
                            console.log('patient email field active')
                            let swalResult = await sweetalert
                                .fire({
                                icon: 'warning',
                                title: "",
                                html: `<h4>Can not send email communications for:</h4> <h5 style="margin-top: 20px;font-weight:bold;">${this.state.services.find(f => f.ID === element.serviceID).Name}</h5><p style="margin-top: 20px;">Please check Facility Management and Patient Field settings</p>`,
                                showCloseButton: false,
                                showDenyButton: true,
                                showCancelButton: false,
                                confirmButtonText: "Continue",
                                denyButtonText: `Go Back`,
                                denyButtonColor: 'green',
                                })
                            if (swalResult.isDenied) {
                                this.setState({ showLoading: false, isSubmitting: false })
                                return;  
                            }
                        }
                        else if(typeof patientInformationCopy.PatientPhone != 'undefined' && typeof patientInformationCopy.PatientEmail === 'undefined' && result.smsIsEnabled && (patientInformationCopy.PatientPhone === null || patientInformationCopy.PatientPhone.length < 1)){
                            console.log('patient phone field active')
                            let swalResult = await sweetalert
                                .fire({
                                icon: 'warning',
                                title: "",
                                html: `<h4>Can not send text communications for:</h4> <h5 style="margin-top: 20px;font-weight:bold;">${this.state.services.find(f => f.ID === element.serviceID).Name}</h5><p style="margin-top: 20px;">Please check Facility Management and Patient Field settings</p>`,
                                showCloseButton: false,
                                showDenyButton: true,
                                showCancelButton: false,
                                confirmButtonText: "Continue",
                                denyButtonText: `Go Back`,
                                denyButtonColor: 'green',
                                })
                            if (swalResult.isDenied) {
                                this.setState({ showLoading: false, isSubmitting: false })
                                return;  
                            }
                        }
                        else{
                            console.log('final')
                        } 
                    }
                }
            }
        }

        if(this.state.servicesObjectsArray.length < 1 || this.state.servicesObjectsArray.length !== this.state.selectedServices.length){
            this.setState({showLoading: false, isSubmitting: false})
            return sweetalert.fire({icon: 'error', title: '', text: "Service Information cannot be blank"});
        }

        //check for duplicate specimenIDs
        if(this.state.servicesObjectsArray.length > 1 ){
            let specimenIDs = []
            this.state.servicesObjectsArray.map(s => {
                specimenIDs.push(s.sampleInfo.specimenID)
            })
            let duplicates = specimenIDs.filter((item, index) => specimenIDs.indexOf(item) !== index)
            if(duplicates.length > 0){
                this.setState({showLoading: false, isSubmitting: false})
                return sweetalert.fire({icon: 'error', title: '', text: "Specimen IDs must be unique"});
            }
        }

        let serviceObjectArrayCopy = JSON.parse(JSON.stringify(this.state.servicesObjectsArray))  

        let submissionFiles = this.state.submissionFiles && this.state.submissionFiles.length > 0 ? this.state.submissionFiles : null;
        
        // let roiObjectsArray = JSON.parse(
        //     JSON.stringify(this.state.roiObjectsArray)
        //   );

        let currentURL = window.location.href;

        let paymentInfo = JSON.parse(JSON.stringify(this.state.paymentInfo))

        this.setState({showLoading:true, isSubmitting: true}, async ()=>{
            try {
                let result = await WalkinAPI.walkinForm(
                    patientInformationCopy, 
                    insuranceInfo, 
                    serviceObjectArrayCopy,
                    insuranceCards, 
                    currentURL, 
                    paymentInfo,
                    submissionFiles
                )

                if(result.success){
                    let successMsg = `<p>Form Submitted Successfully.</p>`
                    if(result.success && result.paymentAuthCode){
                        successMsg += `<p>Payment Authorization: ${result.paymentAuthCode}<br>Transaction ID: ${result.paymentTransactionCode}</br>`
                    }
                    sweetalert.fire({icon: 'success', title: '', html: successMsg});

                    const frontUpload = document.querySelector('#FrontCardCapture') as any;
                    frontUpload.value = '';
                    const backUpload = document.querySelector('#BackCardCapture') as any;
                    backUpload.value = '';
                    let ackCheckbox = document.getElementById("acknowledge") as any;
                    ackCheckbox.checked = false

                    this.setState({
                        isSubmitting: false, 
                        imageSaved:false, 
                        savedPatientInformation: false, 
                        patientInformation: {},
                        insuranceInformation: {},
                        servicesObjectsArray: [],
                        savedInsuranceInformation: false, 
                        savedFileUpload: false, 
                        savedServices: [],
                        collectionDate: new Date(),
                        testOrderedDate: new Date(),
                        savedImportantInformation: false,
                        // savedROIServices: [],
                        acknowledged: false,
                        // roiObjectsArray: [],
                        declineConsent: false,
                        savedPaymentInfo: false,
                        paymentInfo: {formData: null,totalAmount:0,lineItems:null},
                        submissionFiles: []
                    }, () =>{
                        this.clearInsuranceForm(); 
                        this.submissionFileRef.current.clearSubmissionFileModalState();
                        // this.roiRef.current.clearState()
                        this.paymentFormRef.current.clearPaymentFormState();
                        this.patientInfoRef.current.clearForWalkin()
                        PatientAPI.getPatientsFromPatientPortal().then(async data => {
                            this.setState({portalPatients: data.patients})
                        })
                    }); 
                }else{
                    sweetalert.fire({icon: 'error', title: '', html: result.reason.replace(/\n/g, '<br>')});
                }
                this.setState({showLoading: false, isSubmitting: false})
            }
            catch (e) {
                console.error(e);
                sweetalert.fire({icon: 'error', title: '', text: "Failed to submit"});
                this.setState({showLoading: false, isSubmitting: false})
            }
        })
    }

    clear(){
        const frontUpload = document.querySelector('#FrontCardCapture') as any;
        frontUpload.value = '';
        const backUpload = document.querySelector('#BackCardCapture') as any;
        backUpload.value = '';
        let ackCheckbox = document.getElementById("acknowledge") as any;
        ackCheckbox.checked = false
        this.setState({
            isSubmitting: false, 
            imageSaved:false, 
            savedPatientInformation: false, 
            patientInformation: {},
            insuranceInformation: {},
            showInsurance: false,
            showFileUpload: false,
            selectedFacilityIDLabel: null,
            servicesObjectsArray: [],
            savedInsuranceInformation: false, 
            savedFileUpload: false,
            savedServices: [],
            selectedServices: [],
            collectionDate: new Date(),
            testOrderedDate: new Date(),
            savedImportantInformation: false,
            // savedROIServices: [],
            acknowledged: false,
            // roiObjectsArray: [],
            declineConsent: false,
            savedPaymentInfo: false,
            paymentInfo: {formData:null,totalAmount:0,lineItems:null},
            showPaymentInfo: false,
            submissionFiles: []
        }, () =>{
            this.clearInsuranceForm();
            // this.roiRef.current.clearState()
            this.paymentFormRef.current.clearPaymentFormState()
            this.submissionFileRef.current.clearSubmissionFileModalState()
            this.patientInfoRef.current.clearForWalkin()
        }); 
    }

    clearServiceForm = null;

    assignClearService(func){
        this.clearServiceForm = func;
    }

    clearInsuranceForm = null;

    assignClearInsurance(func){
        this.clearInsuranceForm = func;
    }

    handleDateOnChange(value, state){
        if (value) {
            this.setState({[state]: value } as any);
        }
        else{
            this.setState({[state]: null} as any)
        }
    }

    getPricesForService = async () => {
      const facilityID = this.state.selectedFacilityIDLabel.value;
      const serviceIDs = this.state.selectedServices.map(s => s.ID);

      try {
        const response = await PricingAPI.getAllFacilityPricesForService(facilityID, serviceIDs, true);
        const FacilityServicePrices:FacilityPrice[] = response && response.data ? response.data : [];

        this.setState({ associatedPrices: FacilityServicePrices });
      } catch (error) {
        console.error(error);
        sweetalert.fire({icon: 'error', title: '', text: "Failed to get Prices for Facility Service(s)"});
      }
    }

    handleServiceChange(event){
        let tempServices = []
        let tempTestIDs = []
        let tempCustomQuestions = []
        let showInsurance = false
        let showFileUpload = false
        let showPaymentInfo = false

        if(event && event.length > 0 && this.state.services && this.state.services.length > 0){
            event.map(e => {

                for(let i = 0; i < this.state.services.length; i++){
                    if(e.value === this.state.services[i].ID){

                        //selectedServices
                        tempServices.push(this.state.services[i])

                        //assciated tests
                        if(this.state.services[i].Tests && this.state.services[i].Tests.length > 0){
                            tempTestIDs = tempTestIDs.concat(this.state.services[i].Tests)
                        }
                        //associated prices => determined further down when we call this.getPricesForService() 

                        break
                    }
                }

                //customQuestions
                if(this.state.customQuestions && this.state.customQuestions.length > 0){
                    tempCustomQuestions.push(this.state.customQuestions.filter(q => q.ServiceID === e.value && q.Options.isActive))
                }

            })

        }

        tempTestIDs = Array.from(new Set(tempTestIDs))
        let associatedTests = []
        if(tempTestIDs && tempTestIDs.length > 0 && this.state.tests && this.state.tests.length > 0){
            tempTestIDs.map(tt => {
                for(let i = 0; i < this.state.tests.length; i++){
                    if(tt === this.state.tests[i].ID){
                        associatedTests.push(this.state.tests[i])
                        break
                    }
                }
            })
            
        }

        showInsurance = !!tempServices.find(s => s.ShowInsurance)
        showFileUpload = !!tempServices.find(s => s.ShowFileUpload)
        showPaymentInfo = !!tempServices.find(s => s.EnablePayments)
        this.setState({
            selectedServices: tempServices, 
            associatedTests: associatedTests, 
            showInsurance: showInsurance, 
            showFileUpload: showFileUpload, 
            selectedTests: [], 
            savedImportantInformation: false,
            showPaymentInfo: showPaymentInfo,
            paymentInfo: {formData:null,totalAmount:0,lineItems:null},
            savedPaymentInfo:false,
        }, ()=> {
          this.showServiceBlurb(); 
          this.getPricesForService();
        })
    }

    handleSaveServiceSpecificInfo(e){
        let serviceID = e.sampleInfo.serviceID ? e.sampleInfo.serviceID : null
        let savedServices = this.state.savedServices
        let servicesObjectsArray = this.state.servicesObjectsArray
        let showInsurance = this.state.services.find(s => s.ID === serviceID).ShowInsurance;
        let showFileUpload = this.state.services.find(s => s.ID === serviceID).ShowFileUpload;
        let showPaymentInfo = this.state.services.find(s => s.ID === serviceID).ShowPaymentInfo;

        if(serviceID){
            savedServices.push(serviceID)
        }

        if(servicesObjectsArray?.length > 0){
            servicesObjectsArray.map((r, i) => {
                if(r.serviceID === serviceID){
                    servicesObjectsArray.splice(i, 1)
                }
            })
        }

        servicesObjectsArray.push({
            serviceID: serviceID,
            submittedAnswers: e.answeredCustomQuestions,
            sampleInfo: {
                showInsurance: showInsurance,
                showFileUpload: showFileUpload,
                isLabService: e.sampleInfo.isLabService ? e.sampleInfo.isLabService : false,
                memo: e.sampleInfo.memo ? e.sampleInfo.memo : null,
                selectedTests: e.sampleInfo.selectedTests?.length > 0 ? e.sampleInfo.selectedTests : [],
                serviceID: e.sampleInfo.serviceID ? e.sampleInfo.serviceID : null,
                specimenSourceID: e.sampleInfo.specimenSourceID ? e.sampleInfo.specimenSourceID : null,
                specimenID: e.sampleInfo.specimenID ? e.sampleInfo.specimenID : null,
                lot: e.sampleInfo.lot ? e.sampleInfo.lot : null,
                discountCode: e.sampleInfo.discountCode ? e.sampleInfo.discountCode : "",
                showPaymentInfo: showPaymentInfo
            }
        })

        this.setState({
            savedServices: savedServices, 
            servicesObjectsArray: servicesObjectsArray
        })
    }

    // handleRelease(releaseObj) {
    //     let serviceID = releaseObj.serviceID ? releaseObj.serviceID : null;
    //     let savedROIServices = this.state.savedROIServices;
    //     let roiObjectsArray = this.state.roiObjectsArray;
    
    //     if (serviceID) {
    //       if (savedROIServices?.length > 0) {
    //         savedROIServices.map((r, i) => {
    //           if (r === serviceID) {
    //             savedROIServices.splice(i, 1);
    //           }
    //         });
    //       }
    //       savedROIServices.push(serviceID);
    //     }
    
    //     if (roiObjectsArray?.length > 0) {
    //       roiObjectsArray.map((r, i) => {
    //         if (r.serviceID === serviceID) {
    //           roiObjectsArray.splice(i, 1);
    //         }
    //       });
    //     }
    
    //     roiObjectsArray.push({
    //       serviceID: serviceID,
    //       entityID: releaseObj.entityID,
    //     });
    
    //     this.setState({
    //       savedROIServices: savedROIServices,
    //       roiObjectsArray: roiObjectsArray,
    //     });
    //   }
    
    //   handleRemoveRelease(releaseObj) {
    
    //     if(releaseObj === 'all'){
    //       this.setState({
    //         savedROIServices: [],
    //         roiObjectsArray: [],
    //       });
    //       return; 
    //     }
    
    
    //     let serviceID = releaseObj.serviceID ? releaseObj.serviceID : null;
    //     let savedROIServices = this.state.savedROIServices;
    //     let roiObjectsArray = this.state.roiObjectsArray;
    
    //     if (serviceID) {
    //       const index = savedROIServices.indexOf(serviceID);
    //       if (index > -1) {
    //         savedROIServices.splice(index, 1);
    //       }
    //     }
    
    //     if (roiObjectsArray?.length > 0) {
    //       roiObjectsArray.map((r, i) => {
    //         if (r.serviceID === serviceID) {
    //           roiObjectsArray.splice(i, 1);
    //         }
    //       });
    //     }

    
    //     this.setState({
    //       savedROIServices: savedROIServices,
    //       roiObjectsArray: roiObjectsArray,
    //     });
    //   }
    
    handlePaymentInfo = (response, totalPayment, lineItems) =>{
        this.setState({
            savedPaymentInfo: true,
            paymentInfo: { formData: response, totalAmount: totalPayment, lineItems: lineItems },
        });
    }

    showServiceBlurb = () =>{
      // Only showing description for 'Essential/Comprehensive Perio Panels
      const EssentialUpgradeableBlurb = `6Plex + Fn-a Essentials Perio Panel`
      const EssentialNonUpgradeableBlurb = `6Plex Perio Panel`
      const ComprehensiveBlurb = `15Plex Comprehensive Perio Panel`

      if(this.state.selectedServices?.length === 0){ 
        return;
      }

      let theblurb = this.state.selectedServices.map((service)=>{
        let serviceID = service.ID;
        if (
          productionEssentialUpgradableServiceIDs.includes(serviceID) ||
          devEssentialUpgradableServiceIDs.includes(serviceID)
        ) {
          return `${EssentialUpgradeableBlurb}\n`
        }
        if (
          productionComprehensiveServiceIDs.includes(serviceID) ||
          devComprehensiveServiceIDs.includes(serviceID)
        ) {
          return `${ComprehensiveBlurb}\n`
        }
        if (
          productionEssentialNonUpgradableServiceIDs.includes(serviceID) ||
          devEssentialNonUpgradableServiceIDs.includes(serviceID)
        ) {
          return `${EssentialNonUpgradeableBlurb}\n`
        }
      })
      return theblurb;
    }

    
    render(): React.ReactElement | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        let selectedFacilityIDLabel = this.state.selectedFacilityIDLabel ? 
            this.state.selectedFacilityIDLabel : 
            null;
        let availableServiceIDs = this.state.facilities && selectedFacilityIDLabel ? 
            this.state.facilities.find(f => f.ID === selectedFacilityIDLabel.value).ServiceIDs :
            null;
        let parsedServiceIDs = JSON.parse(availableServiceIDs);
        let availableServicesLabels = this.state.serviceLabels && parsedServiceIDs ? 
            this.state.serviceLabels.filter(f => parsedServiceIDs.includes(f.value)) :
            [];
        
        return (
            <React.Fragment>
                <Overlay show_loading={this.state.showLoading}/>

                <ImportantInformationModal
                    services={this.state.services}
                    selectedServices={this.state.selectedServices}
                    setAccepted={() => this.setState({ savedImportantInformation: true })}
                />

                <ServiceFormModal 
                    submit={(e) => this.handleSaveServiceSpecificInfo(e)} 
                    service={this.state.services.find(f => f.ID === this.state.selectedServiceID)} 
                    reviewInfo={this.state.servicesObjectsArray?.length > 0 &&
                        this.state.servicesObjectsArray.find(f => f.serviceID === this.state.selectedServiceID)
                    } 
                    attachClearStateFunc={this.assignClearService} 
                    customQuestions={this.state.serviceSpecificCustomQuestions}
                    associatedTests={this.state.associatedTests} 
                    sources={this.state.validSpecimenSources} 
                    showSampleInfo={true}
                    tests={this.state.tests}
                    associatedPrices={this.state.associatedPrices}
                />

                <PatientInfoModal 
                    ref={this.patientInfoRef}
                    fromWalkin={true}
                    submit={(e) => {
                        this.setState({patientInformation: e.patientInfo, savedPatientInformation: true})
                    }}
                    states={this.state.states}
                    gender={this.state.gender}
                    ethnicity={this.state.ethnicity}
                    race={this.state.race}
                    countries={this.state.countries}
                    portalPatients={this.state.portalPatients}
                    patientFields={this.state.customPatientFields.sort((a,b) => a.Order - b.Order)}
                />

                <InsuranceModal 
                    submit={(e) => this.setState({insuranceInformation: e, savedInsuranceInformation: true})}
                    attachClearStateFunc={this.assignClearInsurance}
                    imageSaved={this.state.imageSaved}
                    handleImageSaved={(e) => this.setState({imageSaved:e})}
                    states={this.state.states}
                />

                {/* <ROIModal
                    ref={this.roiRef}
                    releaseChanged={(r) => this.handleRelease(r)}
                    selectedServices={this.state.selectedServices}
                    services={this.state.services}
                    consentEntities={this.state.consentEntities}
                    handleRemove={(e) => this.handleRemoveRelease(e)}
                    handleRemoveAll={() => this.setState({declineConsent: true}, ()=> this.handleRemoveRelease('all'))}
                    reviewInfo={
                        this.state.roiObjectsArray?.length > 0 &&
                        this.state.roiObjectsArray.find(
                        (f) => f.serviceID === this.state.selectedServiceID
                        )
                    }
                /> */}

                <InformedConsentModal
                    legalName={this.state.legalName}
                    selectedServices={this.state.selectedServices}
                />

                <PaymentFormModal
                    ref={this.paymentFormRef}
                    key={this.state.isSubmitting}
                    selectedServices={this.state.selectedServices}
                    associatedPrices={this.state.associatedPrices}
                    handlePaymentInfo={this.handlePaymentInfo}
                    selectedFacility={this.state.facilities?.find(
                        (f) => { return f.ID === this.state.selectedFacilityIDLabel?.value })
                    }
                    refreshSelectedFacility={this.refreshSelectedFacility}
                    states={this.state.states}
                    countries={this.state.countries}
                    allowDiscountEntry={true}
                    fromWalkin={true}
                />

                <SubmissionFileUploadModal 
                    ref={this.submissionFileRef}
                    clearParentState={() => this.setState({submissionFiles: []})}
                    handleSubmit={(e) => this.setState({submissionFiles: e, savedFileUpload: true})}
                />

                <div className="container-fluid  min-vh-100">
                    <div className={"row"}>
                        <div className=" col-12 col-xl-6 offset-xl-3 pt-2">
                            <main id="main-content" tabIndex={-1} aria-label="Submission Form">
                                <div className="card mt-2">
                                    <div className="card-header verlag-bold">
                                        <h4>Service Selection</h4>
                                    </div>
                                    <div className="card-body">
                                        <div className="form-group row">
                                            <label htmlFor={'Facility'} className="col-sm-4 col-form-label" style={{fontWeight: 'bold'}}>Facility</label>
                                            <div className="col-sm-8  p-0 m-0" id={'Facility'}>
                                                <Select
                                                    name="Facility"
                                                    isSearchable={ true}
                                                    placeholder={<div className="accessibilityText">Please Select...</div>}
                                                    noOptionsMessage={()=>  "No option"}
                                                    className={"state_select"}
                                                    options={this.state.facilityLabels}
                                                    onChange={(e) => this.setState({selectedFacilityIDLabel:e, selectedServices: []})}
                                                    value={this.state.selectedFacilityIDLabel}
                                                    aria-label="Facility"
                                                />
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label htmlFor="serviceDropDown"  className="col-sm-4 col-form-label" style={{fontWeight: 'bold'}}>Service</label>
                                            <div className="col-sm-8  p-0 m-0">
                                                <Select
                                                    name="serviceDropDown"
                                                    isMulti={true}
                                                    isSearchable={ true}
                                                    placeholder={<div className="accessibilityText">Please Select...</div>}
                                                    noOptionsMessage={()=>  "No option"}
                                                    className={"state_select"}
                                                    options={availableServicesLabels}
                                                    onChange={(e) =>  this.handleServiceChange(e) }
                                                    value={this.state.selectedServices.map(s => ({label:s.Name, value:s.ID}))}
                                                    aria-label="Service"
                                                />
                                            </div>
                                        </div>
                                        {this.state.selectedFacilityIDLabel && this.state.selectedServices.length > 0 && ( 
                                          <div className="form-group row justify-content-end text-primary" style={{whiteSpace: "pre-wrap"}}>{ this.showServiceBlurb() } </div>
                                        )}
                                        {/* <div className="form-group row">
                                            <div className={"col-12 col-md-4 pl-3 pl-md-3"}>
                                                <label htmlFor={'Collection Date'} style={{fontWeight: 'bold', paddingTop: '0.5em'}}>Collection Date</label>
                                            </div>
                                            <div className={"d-none d-md-block col-md-8 pl-md-0"} id={'Collection Date'}>
                                                <DatePicker
                                                    placeholderText="--/--/---- --:-- --"
                                                    minDate={new Date('01-01-1000')}
                                                    maxDate={new Date('12-31-9999')}
                                                    showTimeSelect
                                                    selected={this.state.collectionDate ? Date.parse(moment(this.state.collectionDate, 'MM-DD-YYYY h:mm aa').toISOString()) : null}
                                                    dateFormat="MM/dd/yyyy h:mm aa"
                                                    onChange={(e) => this.handleDateOnChange(e, 'collectionDate')}
                                                />
                                            </div>
                                            <div className="row">
                                                <div className={"d-block d-md-none pl-3"}>
                                                    <DatePicker
                                                        placeholderText="--/--/---- --:-- --"
                                                        minDate={new Date('01-01-1000')}
                                                        maxDate={new Date('12-31-9999')}
                                                        showTimeSelect
                                                        selected={this.state.collectionDate ? Date.parse(moment(this.state.collectionDate, 'MM-DD-YYYY h:mm aa').toISOString()) : null}
                                                        dateFormat="MM/dd/yyyy h:mm aa"
                                                        onChange={(e) => this.handleDateOnChange(e, 'collectionDate')}
                                                    />
                                                </div>
                                            </div>
                                        </div> */}
                                        <div className="form-group row">
                                            <div className={"col-12 col-md-4 pl-3 pl-md-3"}>
                                                <label id="DateTestOrdered" htmlFor={'Date Test Ordered'} style={{fontWeight: 'bold', paddingTop: '0.5em'}}>Date Test Ordered</label>
                                            </div>
                                            <div className={"d-none d-md-block col-md-8 pl-md-0"} id={'Date Test Ordered'}>
                                                <DatePicker
                                                    placeholderText="--/--/---- --:-- --"
                                                    minDate={new Date('01-01-1000')}
                                                    maxDate={new Date('12-31-9999')}
                                                    ariaLabelledBy={"DateTestOrdered"}
                                                    showTimeSelect
                                                    selected={this.state.testOrderedDate ? Date.parse(moment(this.state.testOrderedDate, 'MM-DD-YYYY h:mm aa').toISOString()) : null}
                                                    dateFormat="MM/dd/yyyy h:mm aa"
                                                    onChange={(e) => this.handleDateOnChange(e, 'testOrderedDate')}
                                                />
                                            </div>
                                            <div className="row">
                                                <div className={"d-block d-md-none pl-3"}>
                                                    <DatePicker
                                                        placeholderText="--/--/---- --:-- --"
                                                        minDate={new Date('01-01-1000')}
                                                        maxDate={new Date('12-31-9999')}
                                                        ariaLabelledBy={"DateTestOrdered"}
                                                        showTimeSelect
                                                        selected={this.state.testOrderedDate ? Date.parse(moment(this.state.testOrderedDate, 'MM-DD-YYYY h:mm aa').toISOString()) : null}
                                                        dateFormat="MM/dd/yyyy h:mm aa"
                                                        onChange={(e) => this.handleDateOnChange(e, 'testOrderedDate')}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </main>

                            {/* important information section */}
                            {this.state.selectedFacilityIDLabel && this.state.selectedServices.length > 0 && (
                                <div className="mb-2">
                                    <div className="card mt-2">
                                    <div className="card-header verlag-bold">
                                        <div className="row">
                                            <div className="col-10">
                                                <h4>
                                                    Important Information
                                                </h4>
                                            </div>
                                            <div className="col-2">
                                                {this.state.savedImportantInformation ? (
                                                <>
                                                {/* <span className="float-right pl-2">
                                                    <BsCheckCircle color={"#1E6111"} size={37} />
                                                </span> */}
                                                <button
                                                    aria-label={"Important Information Review"}
                                                    className={"btn btn-success verlag-bold"}
                                                    style={{ float: "right" }}
                                                    onClick={() =>
                                                    showModalNoOutsideClick(
                                                        ImportantInformationModal.ID
                                                    )
                                                    }
                                                >
                                                    Review
                                                </button>
                                                </>
                                            ) : (
                                                <button
                                                aria-label={"Important Information Start"}
                                                className={"btn btn-primary verlag-bold"}
                                                style={{ float: "right" }}
                                                onClick={() =>
                                                    showModalNoOutsideClick(
                                                    ImportantInformationModal.ID
                                                    )
                                                }
                                                >
                                                Start
                                                </button>
                                            )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            )}


                            {/* patient information */}
                            {this.state.selectedFacilityIDLabel && this.state.selectedServices.length > 0 && ( 
                                <div className="mb-2">
                                    <div className="card mt-2">
                                        <div className="card-header verlag-bold">
                                        <div className="row">
                                                <div className="col-10">
                                                    <h4>
                                                        Patient Information
                                                    </h4>
                                                </div>
                                                <div className="col-2">
                                                {this.state.savedPatientInformation ? (
                                                <>
                                                    {/* <span className="float-right pl-2">
                                                        <BsCheckCircle color={'#1E6111'} size={37}  />
                                                    </span> */}
                                                    <button 
                                                        aria-label={"Patient Information Review"}
                                                        className={"btn btn-success verlag-bold"}
                                                        style={{float: 'right'}}
                                                        onClick={() => showModalNoOutsideClick(PatientInfoModal.ID)}
                                                    >
                                                        Review
                                                    </button>
                                                </>
                                                )
                                                :
                                                (
                                                    <button 
                                                        className={"btn btn-primary verlag-bold"}
                                                        aria-label={"Patient Information Start"}
                                                        style={{float: 'right'}}
                                                        onClick={() => showModalNoOutsideClick(PatientInfoModal.ID)}
                                                    >
                                                        Start
                                                    </button>
                                                )
                                            }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                )
                            }

                            {/* insurance information  */}
                            {this.state.selectedFacilityIDLabel && this.state.showInsurance && (
                            <div className={"mt-2"}>
                                <div className="card mt-2">
                                    <div className="card-header verlag-bold">
                                    <div className="row">
                                            <div className="col-10">
                                                <h4>
                                                    Insurance Information
                                                </h4>
                                            </div>
                                            <div className="col-2">
                                            {this.state.savedInsuranceInformation ? (
                                            <>
                                                {/* <span className="float-right pl-2">
                                                    <BsCheckCircle color={'#1E6111'} size={37}  />
                                                </span> */}
                                                <button 
                                                aria-label={"Insurance Information Review"}
                                                className={"btn btn-success verlag-bold"}
                                                style={{float: 'right'}}
                                                onClick={() => showModalNoOutsideClick(InsuranceModal.ID)}>
                                                    Review
                                                </button>
                                            </>
                                            )
                                            :
                                            (
                                            <button 
                                                aria-label={"Insurance Information Start"}
                                                className={"btn btn-primary verlag-bold"}
                                                style={{float: 'right'}}
                                                onClick={() => showModalNoOutsideClick(InsuranceModal.ID)}>
                                                Start
                                            </button>
                                            )
                                        }
                                            </div>
                                        </div>
                                 
                                    </div>
                                </div>
                            </div>
                            )}

                            {/* service information  */}
                            {this.state.selectedFacilityIDLabel && this.state.selectedServices?.length > 0 && this.state.selectedServices.map(ss => (
                                <div className="card mt-2">
                                    <div className="card-header verlag-bold">
                                        <div className="row">
                                            <div className="col-10">
                                                <h4>{getNameFromID(ss.ID, this.state.services)}</h4>
                                            </div>
                                            <div className="col-2">
                                            {this.state.savedServices && this.state.savedServices.includes(ss.ID) ?
                                            <>
                                                {/* <span className="float-right pl-2">
                                                    <BsCheckCircle color={'#1E6111'} size={37}  />
                                                </span> */}
                                                <button 
                                                aria-label={"Service Information Review"}
                                                className={"btn btn-success verlag-bold"}
                                                style={{float: 'right'}}
                                                onClick={() => {
                                                    this.setState({
                                                        selectedServiceID: ss.ID, 
                                                        serviceSpecificCustomQuestions: this.state.customQuestions.filter(f => f.ServiceID === ss.ID && f.Options.isActive),
                                                    }, () =>   showModalNoOutsideClick(ServiceFormModal.ID))}}>
                                                    Review
                                                </button>
                                            </>
                                            :
                                            <button 
                                                aria-label={"Service Information Start"}
                                                className={"btn btn-primary verlag-bold"}
                                                style={{float: 'right'}}
                                                onClick={() => {
                                                    this.setState({
                                                     selectedServiceID: ss.ID,
                                                     serviceSpecificCustomQuestions: this.state.customQuestions.filter(f => f.ServiceID === ss.ID && f.Options.isActive),
                                                }, () =>   showModalNoOutsideClick(ServiceFormModal.ID))}}>
                                                Start
                                            </button>
                                            }
                                        
                                        </div>
                                    </div>
                                </div>
                            </div>
                            )) 
                        }

                        {/* ROI section */}
                        {/* {this.state.selectedFacilityIDLabel && this.state.selectedServices?.length > 0 && (
                            <div className="mb-2">
                                <div className="card mt-2">
                                <div className="card-header verlag-bold">
                                <div className="row">
                                            <div className="col-10">
                                                <h4>
                                                    Release of Information
                                                </h4>
                                            </div>
                                            <div className="col-2">
                                                {this.state.savedROIServices.length > 0 || this.state.declineConsent ? (
                                                <>
                                                    <button
                                                        className={"btn btn-success verlag-bold"}
                                                        style={{ float: "right" }}
                                                        onClick={() =>
                                                        showModalNoOutsideClick(
                                                            ROIModal.ID
                                                        )
                                                        }
                                                    >
                                                        Review
                                                    </button>
                                                    </>
                                                ) : (
                                                    <button
                                                    className={"btn btn-primary verlag-bold"}
                                                    style={{ float: "right" }}
                                                    onClick={() =>
                                                        showModalNoOutsideClick(
                                                        ROIModal.ID
                                                        )
                                                    }
                                                    >
                                                    Start
                                                    </button>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            )} */}

                            {/* file upload  */}
                            {this.state.selectedFacilityIDLabel && this.state.showFileUpload && (
                                <div className="card mb-2  mt-2">
                                    <div className="card-header verlag-bold">
                                        <div className="row">
                                            <div className="col-10">
                                                <h4>File Upload</h4>
                                            </div>
                                            <div className="col-2">
                                            {this.state.savedFileUpload ?
                                            <>
                                                <button 
                                                aria-label={"Submission Files Review"}
                                                className={"btn btn-success verlag-bold"}
                                                style={{float: 'right'}}
                                                onClick={() => showModalNoOutsideClick('submissionfileupload')}>
                                                    Review
                                                </button>
                                            </>
                                            :
                                            <button 
                                                aria-label={"Service Information Start"}
                                                className={"btn btn-primary verlag-bold"}
                                                style={{float: 'right'}}
                                                onClick={() => showModalNoOutsideClick('submissionfileupload')}>
                                                Start
                                            </button>
                                            }
                                        
                                        </div>
                                        </div>
                                    </div>
                                </div>
                            )}

                            {/* Payment Information -- this card will provide the Hosted form from Authorize.Net
                                    and will also include a field to enter a Discount Code */}
                            {this.state.selectedFacilityIDLabel && this.state.selectedServices.length > 0 && this.state.showPaymentInfo && (
                                <div className="card mb-2  mt-2">
                                    <div className="card-header verlag-bold">
                                        <div className="row">
                                            <div className="col-10">
                                                <h4>Payment Information</h4>
                                            </div>
                                            <div className="col-2">
                                                {this.state.savedPaymentInfo === true ? (
                                                    <button
                                                        aria-label={"Payment Information Review"}
                                                        className={"btn btn-success verlag-bold"}
                                                        style={{ float: "right" }}
                                                        onClick={() => showModalNoOutsideClick(PaymentFormModal.ID)}
                                                    >Review</button>
                                                ) : (
                                                    <button
                                                        aria-label={"Payment Information Start"}
                                                        className={"btn btn-primary verlag-bold"}
                                                        style={{ float: "right" }}
                                                        onClick={() => showModalNoOutsideClick(PaymentFormModal.ID)}
                                                    >Start</button>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}

                            

                        {this.state.selectedFacilityIDLabel && this.state.selectedServices.length > 0 && (
                            <div className="card mb-2  mt-2">
                                <div className="card-header verlag-bold">
                                    <h4>Summary</h4>
                                </div>
                                <div className="card-body">
                                <div className="row mt-3" id={'infcon'}>
                                    <h4 className="col-12">
                                        <a href="#infcon" className="text-primary" onClick={() => showModalNoOutsideClick(InformedConsentModal.ID) }>Informed Consent</a>
                                    </h4>
                                </div>
                                    <br/>
                                    {this.state.selectedServices.map(ss => (
                                        <div className={"p-2 mb-2"} style={{borderWidth: '1px', borderColor: 'lightgray', borderStyle: 'solid', borderRadius: 15}}>
                                            <h5 className="text-center pb-2">
                                                <span >{ss.Name}</span>
                                               
                                            </h5>
                                            <div>{Parser(ss.Summary)}</div>
                                        </div>
                                    ))}
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" id="acknowledge"
                                           value={this.state.acknowledged}
                                           onChange={(e) => this.setState({acknowledged: e.target.checked})}
                                           aria-required={true}
                                           />
                                    <label className="form-check-label" htmlFor="acknowledge">
                                        I acknowledge and agree that I have read, understand, and agreed to the
                                        statements contained within this form.
                                    </label>
                                </div>
                            </div>
                        </div>
                    )}
                                            
                                        
                                        

                            {/* footer  */}
                            {this.state.selectedFacilityIDLabel && this.state.selectedServices.length > 0 && 
                            <div className={"pr-3 mt-2 pl-3 "} style={{ height: '3.5em', border: '1px solid lightgrey', borderRadius: 5, backgroundColor: '#F7F7F7'}}>
                                <button className={"btn immySubmitButtonOutline float-right my-2"}  onClick={() => {
                                        this.submit();
                                    }} disabled={this.state.isSubmitting}>Submit</button>
                                <button className={"btn immyClearButtonOutline float-left my-2"}  onClick={() => {
                                    this.clear();
                                }} disabled={this.state.isSubmitting}>Clear</button>
                            </div>
                            }
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}